@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lexend", sans-serif;
  font-weight: 400;
}

:root {
  --background-color: #f5f5f5;
  --text-color: #000;
  --button-color: #0077ff;
}

body {
  background: #000;
}

.window {
  height: 100%;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.dark {
  overflow-y: auto;
  --background-image: url("/src/images/app-bg.png");
  --text-color: #fff;
  --button-color: #474747;
  --inner-inner-container-color: #555555;
  --inner-container-color: #474747;
  --button-outer-color: #d8ff00;
  background: var(--background-image);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.light {
  --background-color: #f5f5f5;
  --text-color: #000;
  --button-color: #0077ff;
  --inner-inner-container-color: #dbdbdb;
  --inner-container-color: #c9c9c9;
  background-color: var(--background-color);
}

.header {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  align-items: center;
}

.popup-without-center {
  justify-content: flex-start;
}

.header-logo {
  margin-bottom: 10px;
  width: 100px;
  height: 36px;
}

.cart-header{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: linear-gradient(90deg, #6338a9, #9c5685, #9e794d);
  font-size: 12px;
  padding: 1.25px;
  border-radius: 100px;
  cursor: pointer;
  color: white;
  font-family: "Inter";
}

.button-gradient-inner-bg{
  border-radius: 100px;
  background: linear-gradient(180deg, #434662 0%, #282a3c 100%);
  color: #ffffff;
  width: 100%;
  height: 100%;
  box-shadow: none;
  padding: 8px 20px;
}

.outer-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-x: hidden;
}

.outer-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.outer-container::-webkit-scrollbar-thumb {
  background: #888;
}

.outer-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.row-type-container p {
  display: flex;
  border-radius: 43px;
  background: #2f3050;
  padding: 5px 10px;
  color: #887cb7;
  text-align: center;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.inner-template {
  margin-bottom: 10px;
  outline: 2px dashed transparent;
}

.selected-image {
  outline-color: #00cdfc;
}

.inner-container {
  background: var(--inner-container-color);
  border-radius: 2px;
  padding: 7px;
  display: flex;
  flex-direction: column;
  /* новое свойство */
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.inner-inner-container {
  background: var(--inner-inner-container-color);
  border-radius: 12px;
  width: 333px;
  height: 30px;
}

.inner-container:hover {
  background: #3d3d3d;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.inner-inner-container,
.inner-inner-container-2 {
  transition: 0.3s ease-in-out;
}

.inner-container:hover .inner-inner-container {
  outline: 1px dashed #00cdfc;
  transition: 0.3s ease-in-out;
}

.inner-container:hover .inner-inner-container-2 {
  outline: 1px dashed #00cdfc;
  transition: 0.3s ease-in-out;
}

.inner-template:hover {
  background: #3d3d3d;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.inner-inner-container-2 {
  background: var(--inner-inner-container-color);
  border-radius: 12px;
  width: 333px;
  height: 30px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.inner-template {
  background: #444444;
}

.tabs-container {
  margin-top: 20px;
  height: 50px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  margin-right: 20px;
}

.tabs-wrap {
  display: flex;
  background-color: #2f3050;
  padding: 10px 20px;
  border-radius: 100px;
  gap: 10px;
}

.tab {
  background: linear-gradient(180deg, #282a3c 0%, #434662 100%);
  display: flex;
  width: 80px;
  padding: 5px 13px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 5px 13px;
  border-radius: 9px;
  border: 1px solid #44415b;
  color: #8e9baf;
  cursor: pointer;
  height: 27px;
}

.tabItemContainer {
  display: flex;
  align-items: center;
}

.tabItemWr {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tabTextBox {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.tabText {
  font-family: Manrope;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.tab-check-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tab.active {
  display: inline-flex; /* Adjust as needed for your layout */
  padding: var(--border-width); /* Space for the border */
  border-width: 0;
  border-radius: 9px;
  background: linear-gradient(90deg, #6338a9, #9c5685, #9e794d);
  box-sizing: border-box;
  box-shadow: 0px 3px 3.8px 0px rgba(189, 146, 245, 0.1) inset,
    0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 1px 0.9px 0px rgba(0, 0, 0, 0.36),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1px;
}

.tab.active .tabItemWr,
.tab.active .tab-check-box {
  border-radius: 8px;
  background: linear-gradient(180deg, #434662 0%, #282a3c 100%);
  color: #ffffff;
  width: 100%;
  height: 100%;
  box-shadow: none;
}

.btn {
  margin-left: auto;
  /* добавляем margin-left: auto для кнопки */
}

.main-lable {
  padding-top: 20px;
  padding-bottom: 20px;
}

.main-container {
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-x: hidden;
}

.main-container-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: var(--text-color);
}

.main-container-second-label {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 17px;
  color: #8487bd;
}

.main-lable-text {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 100;
  font-size: 42px;
  line-height: 51px;
  color: var(--text-color);
}

.left-button {
  margin-bottom: 10px;
}

.light-main {
  background-color: #fff;
  color: #333;
}

.dark-main {
  background-color: #1a1a1a;
  color: #fff;
}

.app {
  font-family: "Inter", sans-serif;
  font-size: 22px;
  height: 100%;
  line-height: 20px;
  overflow: hidden;
  width: 100%;
}

html,
body,
#root {
  margin: 0;
  height: 100%;
}

@font-face {
  font-family: "Inter";
  src: url("/public/fonts/inter-variablefont_slntwght-webfont.woff2")
      format("woff2"),
    url("/public/fonts/inter-variablefont_slntwght-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.container {
  height: 136px;
  position: relative;
  overflow: hidden;
}

.logoTemplate-left {
  /* position: absolute; */
  width: 40px;
  height: 40px;
  /* top: 48px; */
  /* left: 19px; */
}

.logoTemplate-right {
  /* position: absolute; */
  /* right: 40px; */
  /* top: 48px; */
  width: 40px;
  height: 40px;
}

.vertical-line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background-color: red;
  z-index: 1;
}

.image {
  width: 1353px;
  height: 116px;
}

.icons-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
  color: white;
}

.icons {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  justify-content: center;
  align-items: flex-start;
  transition: all 0.3s ease-out;
  background-repeat: round;
  background-size: cover;
  background-image: url("./images/bg.png");
  /* width: auto; */
}

.icon-logo-right {
  margin-right: 25px;
}

.icon-logo-left {
  margin-left: 0px;
  position: relative;
  /* background: #ffffff; */
}

/* .icon-logo-left:hover .plus-block {
  display: block;
} */

.icon-left-wrapper {
  position: relative;
  width: 45px;
  height: 45px;
}

.icon-logo-placeholder {
  display: block;
}

.icon-left-wrapper:hover .plus-block {
  display: block;
}

.icon-left-wrapper:hover .icon-logo-placeholder {
  display: none;
}

.icon-left-wrapper:hover .plus-block2 {
  display: block;
}

.icon-left-wrapper:hover .icon-logo-placeholder {
  display: none;
}

.logoBlock {
  margin-left: 20px;
}

.biggerItems {
  height: 82px !important;
}

.imgMarginLeftLess {
  margin-left: 2px !important;
}

.imgMarginRightLess {
  margin-right: 2px !important;
}

.icon {
  width: 72px;
  height: 80.9px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  margin-left: 7px;
  margin-right: 7px;
}

.icon:hover {
  transform: scale(1.2);
}

.icon-bg {
  width: 71px;
  height: 136px;
  background-color: #2c2c2c;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  margin-top: 16px;
}

.input-wrapper input {
  width: 229px;
  height: 39px;
  border-radius: 12px;
  border: 1px solid #645D95;
  background: #2B2D4D;
  color: #ABA7A2;
  padding: 8px;
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
}

.rows-container {
  display: flex;
  /* justify-content: space-between; */
  max-width: 500px;
}

.row-container {
  width: 101px;
  height: 40px;
  background: #474747;
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.row-container.active {
  border: 1px dashed #00cdfc;
}

.row-inner-container {
  background: #727272;
  border-radius: 8px;
  display: flex;
  align-items: center;
  width: 91px;
  height: 30px;
  text-align: center;
  margin: 0 auto;
}

.number {
  background: #474747;
  border-radius: 100%;
  width: 25px;
  height: 24px;
  margin: 0 auto;
  color: white;
  padding: 2px;
  font-size: 12px;
}

.arrow-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  width: 36px;
  height: 36px;
  border: 1px solid #9F95E7;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrow:hover {
  background-color: #2D256A;
}

.arrow:active {
  background-color: #2D256A;
}

.PopupRow {
  backdrop-filter: blur(6px);
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 999999999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PopupRow::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: -1;
  background-color: #0a0a12;
}

.PopupRow_list {
  border: 1px solid #2a2d47;
  background: rgba(23, 23, 38, 0.95);
  backdrop-filter: blur(4px);
  padding: 20px;
  border-radius: 12px;
  max-width: 375px;
  width: 100%;
}

.popup_lorem h1 {
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  margin-top: 20px;
}

.popup_title {
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin-top: 35px;
  margin-bottom: 10px;
}

.popup_subtitle {
  font-weight: 300;
  font-size: 10px;
  color: #62648E;
  line-height: 12px;
}

.header_popup {
  padding: unset;
}

.plug {
  border-radius: 13px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.plug img {
  border-radius: 13px;
  width: 100%;
  height: 100%;
}

.popup_button {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.popup_upload {
  height: 40px;
  background-color: transparent;
  border-radius: 12px;
  font-weight: 400;
  font-size: 14px;
  color: #8e8e8e;
  border: none;
  align-items: center;
  display: flex;
  cursor: pointer;
  outline: none;
}

.popupUploadButton {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.plug {
  position: relative;
  cursor: pointer;
}

.popup_upload img {
  margin-right: 10px;
  margin-left: 10px;
  width: 30px !important;
  height: 30px !important;
}

.popup_save {
  height: 39px;
  border: 1px solid #fff;
  border-radius: 12px;
  background-color: transparent;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
  padding-right: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.popup_save:disabled {
  opacity: 0.2;
}

.popup_lorem p:not(.popup_title){
  color: #887CB7;
  font-family: Manrope;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.popup_save img {
  margin-right: 10px;
  margin-left: 20px;
}

.BurgerPopup {
  backdrop-filter: blur(6px);
  height: 100vh;
  width: 35%;
  position: fixed;
  z-index: 999999999999;
  top: 0;
  right: 0;
  transition: all 0.3s;
}

.BurgerPopup::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  opacity: 0.75;
  z-index: -1;
  background-color: #232535;
}

.BurgerPopup_list {
  padding: 20px;
  border-radius: 12px;
  max-width: 500px;
  width: 100%;
  transition: all 0.3s;
  margin: 0 auto;
}

.activeBurger {
  animation-name: example;
  animation-duration: 0.3s;
  transition: 0.3s;
}

@keyframes example {
  0% {
    right: -35%;
  }

  100% {
    right: 0;
  }
}

.order_block {
  width: 100%;
  background-color: #2F3050;
  position: relative;
  padding: 20px 10px 20px 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-family: "Inter";
}

.order_block p{
  color: #fff;
}

p.order_price{
  color: #8D9EBA;
}

.order_img {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
  height: 66px;
  border-radius: 100%;
  background-color: #4E4F74;
  margin-right: 20px;
}

.deleteBlock {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.BurgerPopup_list h2 {
  font-weight: 100;
  font-size: 20px;
  color: white;
}


.order_name {
  font-weight: 600;
  font-size: 16px;
  font-family: "Inter";
}

.order_price {
  font-weight: 400;
  font-size: 11px;
  font-family: "Inter";
}

.order_price span {
  font-weight: 600;
  font-size: 11px;
  font-family: "Inter";
}

.subtotal {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 15px;
}

.subtotal p {
  font-weight: 500;
  font-size: 13px;
  color: #d9d9d9;
  font-family: "Inter";
}

.BurgerPopup_list hr {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.order_total {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.order_total p {
  font-weight: 600;
  font-size: 18px;
  font-family: "Inter";
  color: white;
}

.order_button {
  width: unset;
  font-weight: 400;
  font-size: 14px;
  font-family: "Inter";
  border-radius: 100px;
  width: 140px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-outer-color);
  border: none;
  cursor: pointer;
}

.order_button_wr {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.order_button img {
  margin-left: 5px;
  width: 20px;
}

.cart-popup-wr {
  display: flex;
  flex-direction: column;
  height: 95vh;
  overflow-y: auto;
}

.order_date {
  font-weight: 500;
  font-size: 10px;
  color: #d9d9d9;
  font-family: "Inter";
  margin-top: 20px;
  margin-bottom: 25px;
  text-align: center;
}

.order_date span {
  font-weight: 700;
  font-size: 10px;
  font-family: "Inter";
}

.order_buttonСontainer {
  margin-top: auto;
}

.tabs_order {
  display: none;
}

.cart-checkout-text {
  color: #000000;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
}

@media (max-width: 850px) {
  .cart-popup-wr {
    height: 85vh;
  }

  .tabs_order {
    display: flex;
    position: absolute;
    bottom: 50px;
    left: 10px;
  }

  .BurgerPopup {
    width: 100%;
  }

  .order_buttonСontainer {
    right: 20px;
  }

  .BurgerPopup_list {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: black;
  }

  .order_total {
    margin-bottom: 20px;
  }

  .BurgerPopup::before {
    opacity: unset;
    background-color: #323232;
  }

  .order_date {
    font-weight: 500;
    font-size: 10px;
    color: #d9d9d9;
    font-family: "Inter";
    margin-bottom: 20px;
    text-align: center;
    right: unset;
  }
}

.center-logo-container:hover {
  background-color: red;
}

@media (max-width: 1260px) {
  .center-text {
    margin-left: 0;
  }
}

@media (max-width: 800px) {
  .tabs-container {
    margin: 20px auto 0 auto;
  }
  .tabs-wrap {
    width: 90vw;
    justify-content: space-between;
  }

  .tab {
    flex-grow: 1;
  }

  /* .container_button {
    margin: 0 auto;
  } */

  .center-text {
    margin-bottom: 8px;
  }

  .vertical-line {
    display: none;
  }

  .outer-container {
    margin-top: 10px;
  }

  .tabs-container {
    /* justify-content: center; */
  }

  .app {
    overflow-y: auto;
  }

  .dark {
    --background-color: #000000;
    --text-color: #fff;
    --button-color: #474747;
    --inner-inner-container-color: #555555;
    --inner-container-color: #474747;
    --button-outer-color: #d8ff00;
    background-color: var(--background-color);
  }
}

@media (max-width: 520px) {
  .rows-container {
    display: flex;
    max-width: 500px;
    flex-wrap: wrap;
    gap: 10px;
  }

  .row-container {
    margin-right: 0;
  }
}



.DYXVr {
  position: absolute !important;
  min-width: 143px !important;
  max-width: 332px !important;
  border: none !important;
}

.DYXVr > input {
  display: none !important;
}

.DYXVr svg {
  display: none !important;
}

.fDoQYg {
  display: none !important;
}

.popup_uploadInput {
  display: none;
}

.inner-inner-container,
.inner-inner-container-2 {
  width: 30vw;
}

.agreement-check {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 12px;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 15px;
}

#agreement-check {
  accent-color: #000;
  border: 1px solid #8e8e8e;
}

.custom-checkbox {
  appearance: none;
  /* Remove default checkbox styles */
  -webkit-appearance: none;
  /* For Safari */
  -moz-appearance: none;
  /* For Firefox */
  width: 15px;
  height: 13.5px;
  border: 3px solid #8e8e8e;
  /* Add a border to the checkbox */
  background-color: black;
  /* Set the background color */
  outline: none;
  /* Remove focus outline */
  vertical-align: middle;
  position: relative;
  top: 2px;
}

.custom-checkbox:checked {
  background-color: var(--button-outer-color);
  /* Set the background color when the checkbox is checked */
}

/* Style the label */

.agreement-check label {
  margin-left: 5px;
  /* Adjust as needed */
}

/* --------------------------------- Footer --------------------------------- */

.wrapper {
  display: flex;
  margin: 30px 0;
  padding: 10px 30px;
  justify-content: space-between;
  align-items: center;
  border-radius: 117px;
  background: #2f3050;
}

.center-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  text-align: center;
}
.center-text p:not(.total-price) {
  color: #887cb7;
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.total-price {
  color: #887cb7;
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.right-container {
  background: var(--button-outer-color);
  display: flex;
  width: 100px;
  height: 30px;
  padding: 13px;
  justify-content: space-between;
  align-items: center;
  border-radius: 41px;
  border: 1px solid #595cac;
  cursor: pointer;
}

.next-step-text {
  display: flex;
  color: #000000;
  color: #000;
  font-family: Manrope;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.left-button-arrow {
  margin-right: 10px;
}

.outer-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--button-outer-color);
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}

.container_button {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  max-width: 390px;
  font-family: "Inter";
}

.previous_button {
  transform: rotate(180deg);
}

.container_button hr {
  background-color: #d8ff00 !important;
  margin-right: 10px;
  margin-left: 10px;
  border: none;
  width: 2px;
}



.icons-container div {
  transition: 0.3s ease-in-out;
}

.icons-container div:hover {
  cursor: pointer;
  background: #3d3d3d;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

.icons-container:hover .icon {
  filter: brightness(1.5);
}

.outer-container {
  margin-top: calc(100vh / 18);
}

.input-wrapper {
  margin-top: 50px;
}

@media screen and (max-width: 400px) {
  .tabs-container {
    /* width: 140px; */
  }

  /* .outer-container {
    scale: 0.65;
    margin-top: -20%;
  } */

  .container {
    scale: 0.5;
  }
}

@media screen and (max-width: 320px) {
  .tabs-container {
    /* width: 100px; */
  }
}

.logo-button {
  cursor: pointer;
  width: 50px;
}

.logoBlock-top {
  width: 30px;
  height: 21px;
  background: black;
  border-radius: 16px;
  transform: rotate(-180deg);
}

.logoBlock-bottom {
  width: 30px;
  height: 21px;
  background: black;
  border-radius: 16px;
  transform: rotate(-180deg);
}

.logoBlock-top {
  position: absolute;
  left: 20px;
  top: 10px;
}

.logoBlock-bottom {
  position: absolute;
  left: 20px;
  bottom: 10px;
}

.icon-logo-left {
  width: 45px;
  height: 45px;
}

.icon-logo-right {
  top: 50%;
  transform: translate(-50%, -50%);
  left: 40px;
  position: absolute;
  width: 45px;
}

.icon-logo-right {
  max-width: 45px !important;
}

.left-logo-container {
  min-width: 60px;
  max-width: 60px;
  padding-left: 0px;
  width: 60px;
}

.right-logo-container {
  min-width: 60px;
  max-width: 60px;
  padding-left: 0px;
}

.icons-text {
  height: 12px;
}

.right-logo-container .logoBlock-bottom {
  position: absolute;
  left: unset;
  right: 20px;
  bottom: 10px;
}

.logoBlock-top {
  position: absolute;
  left: unset;
  bottom: unset;
  right: 20px;
  top: 10px;
}

.icon-logo-right {
  top: 50%;
  transform: translateY(-50%);
  left: unset;
  right: -15px;
  position: absolute;
}

.logoTemplate-right {
  right: 20px;
}

.transparentCard {
  opacity: 0;
  pointer-events: none;
}

.circleWrapper {
  display: flex;
}

.circleWrapperList {
  display: flex;
  flex-wrap: wrap;
}

.small {
  min-width: 150px;
  position: relative;
}

.small img {
  width: 100%;
  position: absolute;
}

.twoRows .small {
  top: 20px;
  right: 30px;
}

.icons-text {
  margin-top: 0;
  margin-bottom: 5px;
}

.active > .icons-text {
  color: #323232;
}

.small .icons-text {
  margin-bottom: 10px;
}

.big .icons-text {
  margin-bottom: 10px;
}

.big {
  min-width: 200px;
  position: relative;
}

.big img {
  width: 100%;
  position: absolute;
}

.twoRows .big {
  top: 5px;
  right: 20px;
}

.logoTemplate-left {
  /* top: 50%; */
  /* transform: translate(-50%, -50%); */
  /* left: 35px; */
  /* position: absolute; */
}

.logoTemplate-right {
  /* top: 50%; */
  /* transform: translate(-50%, -50%); */
  /* left: unset; */
  /* right: 0px; */
  /* position: absolute; */
}

.main-lable-text,
.main-container-second-label {
  text-align: center;
}

.main-lable-text span {
  color: #00cdfc;
  font-weight: 100;
}

.bottom-control-wr {
  margin-top: auto;
}

.second-page-lable .main-lable-text,
.second-page-lable .main-container-second-label {
  /* text-align: left !important; */
}

#thirdLabel {
  text-align: center;
}

.null.active:hover {
  background: #d4d4d4 !important;
  border-radius: 10px;
}

.null.active {
  background: #d4d4d4 !important;
  border-radius: 10px;
}

.plus-block {
  /* top: 50%; */
  /* transform: translate(-50%, -50%); */
  /* left: 38px; */
  /* position: absolute; */
  width: 45px;
  height: 45px;
  background: white;
  border-radius: 4px;
  transition: 0.3s ease-in-out;
}

.plus-block p {
  color: #474747;
  font-size: 28px;
  /* center it vertically and horizontally */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
}

/* .logoBlock:hover .icon-logo-left {
  display: none;
} */
/*
.logoBlock:hover .plus-block {
  display: block;
} */

.plus-block {
  display: none;
}

.logoBlock:hover .plus-block2 {
  display: block;
}

.plus-block2 {
  display: none;
}

.plus-block2 {
  /* top: 50%;
  transform: translate(-50%, -50%);
  right: -13px;
  position: absolute; */
  width: 45px;
  height: 45px;
  background: white;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  border-radius: 4px;
}

.plus-block2 p {
  color: #474747;
  font-size: 28px;
  /* center it vertically and horizontally */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
}

.inner-template {
  max-width: 400px;
  width: 100%;
}

.largeLabel {
  /* transform: scale(1.3); */
}

.largeLabel img {
  object-fit: contain;
}

.inputContainer {
  min-height: calc(150px + 56px);
}

.largeHeight .icons-text {
  height: 15px;
}

.largeHeight {
  margin-bottom: 5px;
}

.savantLogo {
  pointer-events: none;
}

.firstRowLabel {
  position: relative;
  top: 9px;
}

.secondRowLabel {
  position: relative;
  bottom: 15px;
}

.slotRegularTopSize {
  width: 82.8px;
  height: 71.9px;
}

.slotBigBottomSize {
  width: 85px;
  height: 85px;
  margin-top: 2px;
}

@media (max-width: 490px) {
  .tab {
    width: 58px;
  }
  .wrapper {
    /* flex-wrap: wrap; */
    position: fixed;
    bottom: 0;
    width: 90vw;
    margin: 10px auto 30px auto;
    justify-content: space-between;
  }

  .right-container {
    width: 50%;
    padding: 0;
  }

  .outer-button {
    width: 100%;
  }

  .container_button {
    gap: 5px;
  }

  .container {
    scale: 0.8;
  }

  /* .outer-container {
    scale: 1;
  } */

  .outer-container{
    overflow-y: auto;
    padding-bottom: 100px;
  }
  .outer-button {
    justify-content: flex-end;
  }

  .input-wrapper {
    margin-top: 20px;
  }

  .BurgerPopup_list h2 {
    margin-top: 40px;
  }
  .edit-row{
    overflow-y: auto;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 480px) {
  .right-container {
    width: unset;
    padding: 0;
  }

  .inner-inner-container,
  .inner-inner-container-2 {
    width: 85vw;
  }

  .next-step-text {
    font-size: 19px;
    display: none !important;
  }

  .outer-button{
    padding: 10px 30px;
    border-radius: 100px !important;
  }

  .container_button {
    max-width: unset;
    justify-content: unset;
    gap: 5px;
  }

  .main-lable-text {
    /* font-weight: 600; */
    font-size: 22px;
  }

  .main-lable {
    padding: unset;
  }

  .main-container {
    padding: unset;
  }

  .rows-container {
    margin-left: 0px;
  }

  .main-container-label {
    font-size: 25px;
    padding-top: 15px;
  }

  .main-container-second-label {
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .tabs-container {
    /* margin: unset; */
    margin-top: 20px;
  }

  .tab img {
    width: 18px;
  }
}

.icon {
  user-select: none !important;
  pointer-events: none;
}

@media (max-width: 768px) {
  .labelsScroll {
    overflow-x: scroll;
    width: 100%;
  }

  .icons {
    transform: none !important;
    position: relative;
  }

  .container::-webkit-scrollbar {
    display: none;
  }

  .icons {
    transform: none !important;
    /* position: relative; */
    height: 100%;
  }

  .labelsScroll {
    height: 100%;
  }

  .container {
    overflow-y: hidden !important;
  }
}

.containerTop {
  display: flex;
  justify-content: center;
  align-items: center;
}

.containerTop button {
  background: var(--button-outer-color);
  color: #000000;
  width: 150px;
  padding: 15px 25px;
  cursor: pointer;
  border-radius: 12px;
  font-size: 14px;
  border: none;
}

@media (max-width: 768px) {
  .containerTop {
    display: block;
  }
}

.popupUploadWrapper {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.plug {
  width: 100%;
  height: 200px;
  background: white;
  margin-bottom: 10px;
}

.plug img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popup_button {
  display: flex;
  justify-content: flex-end;
}

.templateSelectName {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #00000094;
  opacity: 0;
  transition: 0.3s;
  color: white;
  text-align: center;
  pointer-events: none;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.templateSelectBlock {
  position: relative;
}

.templateSelectBlock:hover .templateSelectName {
  opacity: 1;
}

.templateSelectName p {
  position: absolute;
  top: 10px;
  bottom: 0;
  left: 0;
  right: 0;
}

.template-2 p {
  top: 30px;
}

.template-3 p {
  top: 50px;
}

.template-row {
  scale: 1.1;
  margin-top: 30px;
  margin-bottom: 30px;
  overflow-x: auto;
}

.icons-container {
  transition: 0.3s;
  gap: 0px;
  /* min-width: 1320px; */
  display: flex;
  flex-wrap: wrap;
}

.icons-transparent {
  /* opacity: 0.3 !important; */
  /* pointer-events: fill; */
}

.opacityCell {
  filter: opacity(0.5);
  pointer-events: none;
}

.left-logo-container {
  max-width: 60px;
}

.right-logo-container .icon-logo-right {
  width: 45px;
}

.icons {
  min-width: 1380px;
}

.button2 {
  font-family: sans-serif;
  display: flex;
  width: 93%;
  position: relative;
  transition: 0.5s;
}

.button2 svg,
.button2 svg rect {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100.01%;
  height: 100%;
  fill: transparent;
  border-radius: 10px;
}

.button2 svg rect {
  border-radius: 16px;
  stroke-width: 2;
  stroke: rgba(0, 0, 0, 0.4);
  stroke-dasharray: 20;
  animation: animate 3s linear infinite;
}

@keyframes animate {
  0% {
    stroke-dashoffset: 400;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
